import React, { Component } from 'react';

// import ReactFullpage from '@fullpage/react-fullpage';

import SectionHeader from '../../generals/sectionHeader/SectionHeader';
import SectionTrademarks from '../../generals/sectionTrademarks/SectionTrademarks';
import SectionNoComplications from '../../generals/SectionNoComplications/SectionNoComplications';
import SectionWhatWeDo from '../../generals/sectionWhatWeDo/SectionWhatWeDo';
import SectionWithBeluga from '../../generals/sectionWithBeluga/SectionWithBeluga';
import SectionSteps from '../../generals/sectionSteps/SectionSteps';
import SectionWhiteLabel from '../../generals/sectionWhiteLabel/SectionWhiteLabel';
import SectionContact from '../../generals/sectionContact/SectionContact';
import SectionDownload from '../../generals/sectionDownload/SectionDownload';

import i18n from './../../../i18n';

class Home extends Component {

  componentDidMount() {
    const lang = this.props.lang;
    i18n.changeLanguage((lang === 'en') ? 'en' : 'es');
    this.forceUpdate();
  }

  render() {
    return(
      <div>
        <SectionHeader/>
        <SectionNoComplications/>
        <SectionWhatWeDo/>
        <SectionSteps/>
        <SectionWithBeluga/>
        <SectionWhiteLabel/>
        <SectionTrademarks/>
        <SectionDownload />
        <SectionContact />
      </div>
    )
  }
}

export default Home;
