import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

import Container from '@material-ui/core/Container';

import './SectionTrademarks.scss';

class SectionTrademarks extends Component  {

  render() {
    return (
      <div className="trade-container" id="SectionTrademarks">
        <div className="content-trademark">
          <h1
            data-aos="fade-up"
            data-aos-once="true"
            >
              <Trans i18nKey="sectionTrademarks.tittleStrong" />
               <span>
                <Trans i18nKey="sectionTrademarks.tittleRegular" />
               </span>
          </h1>
          <Container maxWidth="lg">
            <div 
              className="cards cards-cert responsive"
              data-aos="fade-up"
              data-aos-once="true"
              >
              <img src="./img/024_Visa.svg" alt="visa"/>
              <img src="./img/025_Mastecard.svg" alt="masterCard"/>
              <img src="./img/029_DOW.svg" alt="dow"/>
              <img src="./img/031_Dupont.svg" alt="dupont"/>
              <img src="./img/032_Corteva.svg" alt="corteva"/>
              <img src="./img/033_Banorte.svg" alt="corteva"/>
              <img src="./img/034_PCI.svg" alt="corteva"/>
              <img src="./img/035_BancoMexico.svg" alt="corteva"/>
              <img src="./img/036_samsungpay.svg" alt="corteva"/>
              <img src="./img/037_canet.svg" alt="corteva"/>
              <img src="./img/026_bloomberg.svg" alt="bloomberg"/>
              <img src="./img/027_forbes.svg" alt="forbes"/>
              <img src="./img/028_MIT.svg" alt="MIT"/>
              <img src="./img/030_tech.svg" alt="TC"/>
            </div>
          </Container>
        </div>
      </div>
    )
  } 
}

export default withTranslation()(SectionTrademarks);
