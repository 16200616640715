const en = {
    get: 'Get ',
    espiral: 'Espiralapp ',
    mpos: 'MPOS',
    contact: 'CONTACT US',
    paragraphReg: 'Know our products, <br/> download our app for collections <br/> and ',
    paragraphBold: 'accept payments today',
    createAcc: 'CREATE AN ACCOUNT'
};

const es = {
    get: 'Crea tu cuenta ',
    espiral: 'Espiralapp ',
    mpos: 'MPOS',
    contact: 'CONTACTANOS',
    paragraphReg: 'Conoce nuestros productos, <br/> descarga nuestra app para cobros <br/> y ',
    paragraphBold: 'acepta pagos hoy',
    createAcc: 'CREA UNA CUENTA'
};

module.exports = {
    en,
    es
};
