const en = {
    payments: 'Accept payments',
    without: 'without complications',
    isPos: ' is the POS that helps your bussiness sell anything, anywhere.',
    process: 'Process payments and send receipts to your clients by email or to any messaging platform',
    youCan: 'You can accept payments with:'
}

const es = {
    payments: 'Realiza cobros',
    without: 'sin complicaciones',
    isPos: ' es el punto de venta que ayuda a tu negocio a vender donde sea y cuando quieras.',
    process: 'Procesa pagos y envía los recibos a tus clientes por email o a cualquier plataforma de mensajería',
    youCan: 'Puedes cobrar con:'
}

module.exports = {
    en,
    es
};