const en = {
    tittleStrong: 'Certifications ',
    tittleRegular: 'and alliances'
};

const es = {
    tittleStrong: 'Cerficiaciones ',
    tittleRegular: 'y alianzas'
};

module.exports = {
    en,
    es
};