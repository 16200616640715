const en = {
    make: 'Make better decisions.',
    track: 'Track all your sales, online',
    offline: 'and offline in one place.'
};

const es = {
    make: 'Toma mejores desiciones',
    track: 'Monitorea tus ventas, en línea',
    offline: 'y fuera de línea en un solo lugar'
};

module.exports = {
    en, 
    es
}