const en = {
    tittleStrong: 'White Label',
    tittleRegular: 'Custom solutions',
    parag1: 'At Espiralapp we make technology work for you and your business, providing tailored solutions',
    parag2: 'Comprehensive systems in MPOS devices for card-present payment',
    parag3: 'Personalized collection app linked by a reader directly to your mobile device.',
    parag4: 'Monitoring of transactions through our dashboard',
    btn: 'CONTACT AN ADVISOR'
};

const es = {
    tittleStrong: 'White Label',
    tittleRegular: 'Soluciones personalizadas',
    parag1: 'En Espiralapp hacemos que la tecnología trabaje para usted y su negocio, proporcionando soluciones a la medida.',
    parag2: 'Sistemas integrales en dispositivos MPOS para cobro con tarjeta presente',
    parag3: 'App de cobro personalizada y vinculada mediante un lector directo a su dispositivo móvil.',
    parag4: 'Monitoreo de las transacciones a traves nuestro dashboard',
    btn: 'CONTACTA UN ASESOR'
};

module.exports = {
    en,
    es
};