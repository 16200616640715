import sectionHeader from './sections/sectionHeader';
import sectionNoComplications from './sections/sectionNoCompications';
import sectionSteps from './sections/sectionSteps';
import sectionWithBeluga from './sections/sectionWithBeluga';
import sectionWhiteLabel from './sections/sectionWhiteLabel';
import sectionTrademarks from './sections/sectionTrademarks';


import menu from './sections/menu';
import sectionWhatWeDo from './sections/sectionWhatWeDo';
import instantDeposits from './sections/instantDeposits';
import instantPayments from './sections/instantPayments';
import allInOne from './sections/allInOne';
import sectionDownload from './sections/sectionDownload';
import sectionContact from './sections/sectionContact';
import footer from './sections/footer';

export default {
  en: {
    sectionHeader: sectionHeader.en,
    sectionNoComplications: sectionNoComplications.en,
    sectionWhatWeDo: sectionWhatWeDo.en,
    sectionSteps: sectionSteps.en,
    sectionWithBeluga: sectionWithBeluga.en,
    sectionWhiteLabel: sectionWhiteLabel.en,
    sectionTrademarks: sectionTrademarks.en,
    sectionDownload: sectionDownload.en,
    sectionContact: sectionContact.en,

    // menu: menu.en,
    
    // instantDeposits: instantDeposits.en,
    // instantPayments: instantPayments.en,
    // allInOne: allInOne.en,
    // footer: footer.en
  },

  es: {
    sectionHeader: sectionHeader.es,
    sectionNoComplications: sectionNoComplications.es,
    sectionWhatWeDo: sectionWhatWeDo.es,
    sectionSteps: sectionSteps.es,
    sectionWithBeluga: sectionWithBeluga.es,
    sectionWhiteLabel: sectionWhiteLabel.es,
    sectionTrademarks: sectionTrademarks.es,
    sectionDownload: sectionDownload.es,
    sectionContact: sectionContact.es,

    // menu: menu.es,    
    // instantDeposits: instantDeposits.es,
    // instantPayments: instantPayments.es,
    // allInOne: allInOne.es,
    // footer: footer.es    
  }
}
