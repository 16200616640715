const en = {
    tittleStrong: 'With Espiralapp ',
    tittleRegular: 'you can check in your dashboard ...',
    parag1: 'Where a transaction was made, on the day and time you require.',
    parag2: 'All your transactions in one place.',
    parag3: 'Manage all of our products.',
    parag4: 'Register businesses, devices and users.',
    parag5: 'Reconcile your sales immediately.',
    parag6: 'Check the status of your transactions at the time of making them.',
    parag7: 'Download weekly or monthly reports, do not lose the thread of your sales.'
};

const es = {
    tittleStrong: 'Con Espiralapp ',
    tittleRegular: 'puedes revisar en tu dashboard...',
    parag1: 'En donde fue realizada una transacción, en el día y la hora que lo requieras.',
    parag2: 'Todas tus transacciones en un solo lugar.',
    parag3: 'Administra todos nuestros productos.',
    parag4: 'Dar de alta comercios, dispositivos y usuarios.',
    parag5: 'Concilia tus ventas de forma inmediata.',
    parag6: 'Revisa el estatus de tus transacciones al momento de realizarlas.',
    parag7: 'Descarga reportes semanales o mensuales, no pierdas el hilo de tus ventas.'
};

module.exports = {
    en,
    es
};