import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import './SectionWhatWeDo.scss';

class SectionWhatWeDo extends Component  {
  render() {
    return (
      <div className="gradient" id="SectionWhatWeDo">
        <section className="section-download-app flex">
          <div className="content">
            <div
              className="content-title"
              data-aos="fade-up"
              data-aos-once="true"
              >
              <h1>
                <Trans i18nKey="sectionWhatWeDo.DownloadConnect" /> <span><Trans i18nKey="sectionWhatWeDo.yourTerminal" /></span>
              </h1>
            </div>
            <div
              className="content-stores flex"
              data-aos="fade-up"
              data-aos-once="true"
              >
                <a href="#" rel="noopener noreferrer" target="_blank">
                  <img src="./img/014_Logoappstore.svg" alt="appstore"/>
                </a>
                <a href="#" rel="noopener noreferrer" target="_blank">
                  <img src="./img/015_logoPlay.svg" alt=""/>
                </a>
            </div>
            <div 
              className="content-description"
              data-aos="fade-up"
              data-aos-once="true"
              >
              <p>
              <span>Espiralapp</span> <Trans i18nKey="sectionWhatWeDo.likeBank" />
              </p>
            </div>
            <div
              className="content-comision mob-center"
              data-aos="fade-up"
              data-aos-once="true"
              >
              <p><Trans i18nKey="sectionWhatWeDo.Commissions" /></p>
              <h1>3.5% + <Trans i18nKey="sectionWhatWeDo.tax" /></h1>
            </div>
            <div 
              className="content-payments mob-center"
              data-aos="fade-up"
              data-aos-once="true"
              >
              <p><Trans i18nKey="sectionWhatWeDo.Deferred" /></p>
              <div className="table">
                <div>
                  <h1>3 <Trans i18nKey="sectionWhatWeDo.months" /> 3% + <Trans i18nKey="sectionWhatWeDo.tax" /></h1>
                </div>
                <div>
                  <h1>6 <Trans i18nKey="sectionWhatWeDo.months" /> 6% + <Trans i18nKey="sectionWhatWeDo.tax" /></h1>
                </div>
                <div>
                  <h1>9 <Trans i18nKey="sectionWhatWeDo.months" /> 9% + <Trans i18nKey="sectionWhatWeDo.tax" /></h1>
                </div>
                <div>
                  <h1>12 <Trans i18nKey="sectionWhatWeDo.months" /> 12% + <Trans i18nKey="sectionWhatWeDo.tax" /></h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  } 
}

export default withTranslation()(SectionWhatWeDo);
