import React, { Component } from 'react';
import { withTranslation,Trans } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
// import Container from '@material-ui/core/Container';
// import AppBar from '@material-ui/core/AppBar';

import './SectionWhiteLabel.scss';

class SectionWhiteLabel extends Component  {
  render() {
    return (
        <div className="wl-gradient" id="SectionWhiteLabel">
            <Container maxWidth="lg">
                <section className="content-wl">
                    <div className="wl">
                        <div className="wl-header">
                            <div 
                                className="wl-header__title text-blue-dark-beluga"
                                data-aos="fade-up"
                                data-aos-once="true"
                                >
                                <h1>
                                    <Trans i18nKey="sectionWhiteLabel.tittleStrong" />
                                </h1>
                                <h2>
                                    <Trans i18nKey="sectionWhiteLabel.tittleRegular" />
                                </h2>
                            </div>
                        </div>
                        <div 
                            className="wl-content"
                            data-aos="fade-up"
                            data-aos-once="true"
                            >
                            <p>
                                <Trans i18nKey="sectionWhiteLabel.parag1" />
                            </p>
                            <p>
                                <Trans i18nKey="sectionWhiteLabel.parag2" />
                            </p>
                            <p>
                                <Trans i18nKey="sectionWhiteLabel.parag3" />
                            </p>
                            <p>
                                <Trans i18nKey="sectionWhiteLabel.parag4" />
                            </p>
                            <div 
                                className="os"
                                data-aos="fade-up"
                                data-aos-once="true"
                                >
                                <a href="tel:+3317216417">
                                    <Button variant="outlined">
                                        <Trans i18nKey="sectionWhiteLabel.btn" />
                                    </Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </div>
    )
  } 
}

export default withTranslation()(SectionWhiteLabel);
