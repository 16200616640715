import React, { Component } from 'react';

import Fab from '@material-ui/core/Fab';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Container from '@material-ui/core/Container';
// import Hidden from '@material-ui/core/Hidden';

import AppBarShow from './../appBarShow/AppBarShow';
import Menu from './menu/Menu.js';

class NavBar extends Component {
  state = {
    right: false,
  }

  render() {
    const toggleDrawer = (side, open) => event => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      this.setState({ ...this.state, [side]: open });
    };

    const closeToggleDrawer = (side, open) => event => {
      this.setState({ ...this.state, [side]: open });
    }

    return (
      <div>
          <div className="menu-mobile">
            <div>
              <Fab className="btn-icon-menu" aria-label="add" onClick={toggleDrawer('right', true)}>
                <img className="icon-show-menu" src="./img/003_icon_menu.png" alt="EspiralappLogo"/>
              </Fab>
              <SwipeableDrawer
                anchor="right"
                open={this.state.right}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}
              >
                <div
                  role="presentation"
                  onClick={toggleDrawer('right', false)}
                  onKeyDown={toggleDrawer('right', false)}
                >
                  <Menu closeToggleDrawer={closeToggleDrawer('right', false)}/>
                </div>
              </SwipeableDrawer>
            </div>
          </div>
      </div>
    );
  }
}

export default NavBar;