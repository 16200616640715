const en = {
    getPaid: 'Get paid ',
    minutes: 'within minutes',
    noWaiting: 'No more waiting'
};

const es= {
    getPaid: 'Recibe tu dinero ',
    minutes: 'en minutos',
    noWaiting: 'No esperes más'
};

module.exports = {
    en,
    es
}
