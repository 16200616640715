import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translations from './translations/index';

const resources = {
  en: {
    translation: translations.en
  },
  es: {
    translation: translations.es
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "es",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;