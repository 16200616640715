import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import './SectionWithBeluga.scss';

class SectionWithBeluga extends Component  {
  render() {
    return (
        <section className="section-withBeluga flex text-blue-dark-beluga" id="SectionWithEspiral">
          <div className="content flex responsive">
            <div 
              className="content-left"
              data-aos="fade-up"
              data-aos-once="true"
              >
                <img src="./img/004_icon_mpos.png" alt="mpos_logo"/>
            </div>
            <div>
                <div
                  className="content-title"
                  data-aos="fade-up"
                  data-aos-once="true"
                  >
                  <h1>
                      <span>
                        <Trans i18nKey="sectionWithBeluga.tittleStrong" />
                      </span>
                      <Trans i18nKey="sectionWithBeluga.tittleRegular" />
                  </h1>
                </div>
                <div 
                  className="content-description"
                  data-aos="fade-up"
                  data-aos-once="true"
                  >
                <p>
                  <Trans i18nKey="sectionWithBeluga." />
                    En donde fue realizada una transacción, en el día y la hora que lo requieras.
                </p>
                <p>
                  <Trans i18nKey="sectionWithBeluga.parag1" />
                    Todas tus transacciones en un solo lugar.
                </p>
                <p>
                  <Trans i18nKey="sectionWithBeluga.parag2" />
                </p>
                <p>
                  <Trans i18nKey="sectionWithBeluga.parag3" />
                </p>
                <p>
                  <Trans i18nKey="sectionWithBeluga.parag4" />
                </p>
                <p>
                  <Trans i18nKey="sectionWithBeluga.parag5" />
                </p>
                <p>
                  <Trans i18nKey="sectionWithBeluga.parag6" />
                </p>
                </div>
            </div>
          </div>
        </section>
    )
  } 
}

export default withTranslation()(SectionWithBeluga);
