const en = {
    acceptPayments: 'Accept payments',
    complications: 'No complications',
    download: 'Download',
    ourPos: 'Our POS',
    review: 'Review transactions',
    whiteLabel: 'White label',
    certification: 'Certifications',
    getBelugapay: 'Get Espiralapp',
    contact: 'Contact us'
};

const es = {
   acceptPayments: 'Acepta pagos',
    complications: 'Sin complicaciones',
    download: 'Descarga la app',
    ourPos: 'Terminales',
    review: 'Dashboard',
    whiteLabel: 'White label',
    certification: 'Certificaciones y alianzas',
    getBelugapay: 'Obten Espiralapp',
    contact: 'Contactanos'
};

module.exports = {
   en,
   es
};