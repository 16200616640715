import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import {
  Link
} from "react-router-dom";
// import i18n from './../../../../i18n';

import './Menu.scss';

class Menu extends Component {

  // changeLanguage(lng) {
  //   i18n.changeLanguage(lng);
  // }

  render() {
    return (
      <div className={`container-menu ${this.props.mode}`}>
        <div className="container-logo">
          <img className="image-logo" src="./img/016_logoespiralapp-8.png" alt="logoBeluga"/>
        </div>
        <div className="container-bts">
          <ul className="nav__list">
            <li className="nav__item">
              <a href="#sectionHeader" className="nav__link">Acepta pagos</a>
            </li>
            <li className="nav__item">
              <a href="#SectionNoComplications" className="nav__link">Sin complicaciones</a>
            </li>
            <li className="nav__item">
              <a href="#SectionWhatWeDo" className="nav__link">Descarga la app</a>
            </li>
            <li className="nav__item">
              <a href="#SectionSteps" className="nav__link">Terminales</a>
            </li>
            <li className="nav__item">
              <a href="#SectionWithEspiral" className="nav__link">Dashboard</a>
            </li>
            <li className="nav__item">
              <a href="#SectionWhiteLabel" className="nav__link">White label</a>
            </li>
            <li className="nav__item">
              <a href="#SectionTrademarks" className="nav__link">Certificaciones y alianzas</a>
            </li>
            <li className="nav__item">
              <a href="#SectionGetEspiral" className="nav__link">Obten Espiralapp</a>
            </li>
            <li className="nav__item">
              <a href="#SectionFooter" className="nav__link">Contáctanos</a>
            </li>
          </ul>
          
          <div className="buttons">
            <a href="https://dash.espiralapp.com/es/login" target="_blank" rel="noopener noreferrer">
              <Button className="btn-menu">
                Crea tu usuario
              </Button> 
            </a>
            <a href="tel:+3317216417">
              <Button className="btn-menu call">
                Llamanos
              </Button> 
            </a>
          </div>
          {/* <div className="container-btn-flags">
            <Button className="expand-link" onClick={() => this.changeLanguage('es')}>
              <img className="icon-flag flag-black" src="./img/038_FlagMx.svg" alt="blackMxFlag"/>
              <img className="icon-flag flag-color" src="./img/FlagMEXICO_color.svg" alt="colorMxFlag"/>
            </Button>
            <Button className="expand-link" onClick={() => this.changeLanguage('en')}>
              <img className="icon-flag flag-black" src="./img/039_FlagEU_1.svg" alt="blackUSFlag"/>
              <img className="icon-flag flag-color" src="./img/FlagEUA_color.svg" alt="colorUSFlag"/>
            </Button>
          </div> */}
        </div>
      </div>
    );
  }
}

export default Menu;