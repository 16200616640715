const en = {
    DownloadConnect: 'Download our app and connect',
    yourTerminal: 'your new terminal',
    likeBank: 'works like a bank, but instead of having ATMs and slow paperwork, we focus on making technology work for your business.',
    Commissions: 'Commissions:',
    tax: 'TAX',
    Deferred: 'Deferred payments from $ 300 pesos:',
    months: 'months'
};

const es = {
    DownloadConnect: 'Descarga nuestra app y conecta',
    yourTerminal: 'tu nuevo lector',
    likeBank: 'funciona como un banco, pero en lugar de tener cajeros y tramites lentos, nos enfocamos en hacer que la tecnología trabaje para tu negocio.',
    Commissions: 'Comisiones de:',
    tax: 'IVA',
    Deferred: 'Pagos diferidos a partir de $300 pesos:',
    months: 'meses'
};

module.exports = {
    en,
    es
};