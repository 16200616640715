const en = {
    email: 'Your email',
    comments: 'Your comment...',
    hear: 'We want to hear you',
    paragRegular: 'Our ',
    paragBold: 'advisers ',
    paragRegular2: 'are ready to work hand in hand with you',
    send: 'SEND',
    followUs: 'FOLLOW US',
    conditions: '© 2021 Espiral Technologies de México SA de CV Terms and Conditions & Privacy Policy.'
};

const es = {
    email: 'Tu correo',
    comments: 'Tu comentario...',
    hear: 'Queremos escucharte',
    paragRegular: 'Nuestros ',
    paragBold: 'asesores ',
    paragRegular2: 'están listos para trabajar de la mano contigo',
    send: 'ENVIAR',
    followUs: 'Síguenos',
    conditions: '© 2021 Espiral Technologies de México SA de CV <Link to="/terms-and-conditions">Terminos y condiciones & Politicas de Privacidad.</Link>'
};

module.exports = {
    en,
    es
};
