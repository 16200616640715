const en = {
   accept: 'ACCEPT',
   paymentsToday: 'PAYMENTS TODAY, PRESENTIAL OR ONLINE',
   contact: 'CONTACT US'
};

const es = {
    accept: 'ACEPTA',
    paymentsToday: 'PAGOS HOY, PRESENCIALES Y EN LINEA',
    contact: 'CONTÁCTANOS'
};

module.exports = {
    en,
    es
};