import React from 'react';
import PropTypes from 'prop-types';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

import './AppBarShow.scss';

function AppBarShow(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100
  });

  return (
    <div className="container-slider-menu">
      <Slide appear={false} direction="down" in={trigger}>
        {children}
      </Slide>
    </div>
  );
}

AppBarShow.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};
export default AppBarShow;