import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import './SectionNoComplications.scss';

class SectionNoComplications extends Component  {

  render() {
    return (
      <section className="content-demo" id="SectionNoComplications">
        <div className="info">
          <div className="info-header flex">
            <div className="info-header__logo">
              <img src="./img/004_icon_mpos.png" alt="terminal"/>
            </div>
            <div 
              className="info-header__title text-blue-dark-beluga"
              data-aos="fade-up"
              data-aos-once="true"
              >
              <h1>
                <Trans i18nKey="sectionNoComplications.payments" />
              </h1>
              <h2>
                <Trans i18nKey="sectionNoComplications.without" />
              </h2>
            </div>
          </div>
          <div 
            className="info-content"
            data-aos="fade-up"
            data-aos-once="true"
            >
            <p>
              <span>Espiralapp MPOS</span> <Trans i18nKey="sectionNoComplications.isPos" />
            </p>
            <p>
              <Trans i18nKey="sectionNoComplications.process" />
            </p>
            <p>
              <Trans i18nKey="sectionNoComplications.youCan" />
            </p>
            <div 
              className="cards flex responsive"
              data-aos="fade-up"
              data-aos-once="true"
              >
              <img src="./img/005_logo_mastercard.png" alt="mc_logo"/>
              <img src="./img/006_samsung.png" alt="samsung_logo"/>
              <img src="./img/007_visa.png" alt="visa_logo"/>
              <img src="./img/008_carnet.png" alt="carnet_logo"/>
              <img src="./img/009_sivale.png" alt="sivale_logo"/>
            </div>
          </div>
        </div>
      </section>
    )
  } 
}

export default withTranslation()(SectionNoComplications);