const en = {
    tittleStrong: 'Our terminals',
    tittleRegular: 'points of sale',
    parag1: 'We have BBpos Certified Terminals.',
    parag2: 'You can know the health of your terminals in real time, we will notify you in case of communication failures with your linked device.',
    parag3: 'Updates without having to have a technician on site, dedicate yourself to getting paid.',
    parag4: 'Our terminals are ready to be integrated with your system as points of sale, ERP’s and App’s.',
    parag5: '2 years warranty, market leaders.',
    terminal: 'PINPAD WITH PRINTER',
    price1: '$1,341.26 MXN + TAX',
    price2: '$2,341.34 MXN + TAX'
};

const es = {
    tittleStrong: 'Nuestras terminales',
    tittleRegular: 'puntos de venta',
    parag1: 'Contamos con Terminales Certificadas BBpos.',
    parag2: 'Puedes conocer la salud de tus terminales <br/> en tiempo real, te notificaremos en caso <br/> de fallas en la comunicación con tu dispositivo vinculado.',
    parag3: 'Actualizaciones sin necesidad de tener a un técnico en sitio, dedicate a cobrar.',
    parag4: 'Nuestras terminales están listas para <br/> ser integradas con tu sistema como puntos <br/> de venta, ERP’s y App’s.',
    parag5: '2 años de garantía, lideres del mercado.',
    terminal: 'PINPAD CON IMPRESORA',
    price1: '$1,341.26 + IVA',
    price2: '$2,341.34 + IVA'
};

module.exports = {
    en,
    es
};