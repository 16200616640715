import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import './SectionSteps.scss';

class SectionSteps extends Component  {
  render() {
    return (
      <section className="content-pos" id="SectionSteps">
        <div className="pos flex">
          <div className="pos-content">
          <div className="pos-header">
            <div 
              className="pos-header__title text-blue-dark-beluga"
              data-aos="fade-up"
              data-aos-once="true"
              >
              <h1>
                <Trans i18nKey="sectionSteps.tittleStrong" />
              </h1>
              <h2>
              <Trans i18nKey="sectionSteps.tittleRegular" />
              </h2>
            </div>
          </div>
            <p
              data-aos="fade-up"
              data-aos-once="true"
              >
              <Trans i18nKey="sectionSteps.parag1" />
            </p>
            <p
              data-aos="fade-up"
              data-aos-once="true"
              >
              <Trans i18nKey="sectionSteps.parag2" />
            </p>
            <p
              data-aos="fade-up"
              data-aos-once="true"
              >
              <Trans i18nKey="sectionSteps.parag3" />
            </p>
            <p
            data-aos="fade-up"
            data-aos-once="true"
              >
              <Trans i18nKey="sectionSteps.parag4" />
            </p>
            <p
            data-aos="fade-up"
            data-aos-once="true"
              >
              <Trans i18nKey="sectionSteps.parag5" />
            </p>
            <div 
              className="os"
              data-aos="fade-up"
              data-aos-once="true"
              >
                <img src="./img/012_android.png" alt=""/>
                <img src="./img/013_iOS-8.png" alt=""/>
                <img className="microsoft" src="./img/014_microsoft-8.png" alt=""/>
            </div>
          </div>
        </div>
        <div 
          className="mobile-terminal"
          data-aos="fade-up"
          data-aos-once="true"
          >   
              <picture>
                <source media="(max-width: 1024px)" srcSet="./img/013_lectores.png"/>
                <source media="(max-width: 3000px)" srcSet="./img/013_lectores.png"/>
                <img src="./img/013_lectores.png" alt=""/>
              </picture>
              <div
                className="pinpad-mob abs tag"
                data-aos="fade-up"
                data-aos-once="true"
                >
                <h3>PINPAD</h3>
                <h3>
                <Trans i18nKey="sectionSteps.price1" />
                </h3>
              </div>
              <div 
                className="printer-mob abs tag"
                data-aos="fade-up"
                data-aos-once="true"
                >
                <h3>
                  <Trans i18nKey="sectionSteps.terminal" />
                </h3>
                <h3><Trans i18nKey="sectionSteps.price2" /></h3>
              </div>
            </div>
      </section>
    )
  } 
}

export default withTranslation()(SectionSteps);
