const en = {
    follow: 'Follow us',
    about: 'About',
    careers: 'Careers',
    products: 'Products',
    merchants: 'Merchants',
    restaurants: 'Restaurants',
    crypto: 'Crypto Wallet',
    raodmap: 'Roadmap',
    pricing: 'Pricing',
    contacts: 'Contacts',
    faq: 'FAQ',
    legal: 'Legal'
};

const es = {
    follow: 'Síguenos en',
    about: 'Acerca de',
    careers: 'Carreras',
    products: 'Productos',
    merchants: 'Comerciantes',
    restaurants: 'Restaurantes',
    crypto: 'Crypto Wallet',
    raodmap: 'Trayectoria',
    pricing: 'Precios',
    contacts: 'Contactos',
    faq: 'FAQ',
    legal: 'Legal'
};

module.exports = {
    en,
    es
};